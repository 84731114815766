// start the Stimulus application
import './bootstrap';

import 'datatables.net';
import 'datatables.net-rowreorder-dt';
import 'select2';

// any CSS you import will output into a single css file (app.scss in this case)
import './styles/app.css';

// Window extensions
import './js/extensions';

// extend datatables to define defaults
$.extend(true, $.fn.dataTable.defaults, {
    //dom: "<'dt-length'<'col-md-1'l><'dt-search m-3'f>r>t<'row2'<'col-md-3'i><'col-md-4'p>>",
    autoWidth: false,
    pageLength: 25,
    lengthMenu: [
        [10, 25, 50, 100, -1],
        [10, 25, 50, 100, 'All'],
    ],
    language: {
        info: "Showing <span class='font-medium text-slate-600'>_START_</span> to <span class='font-medium text-slate-600'>_END_</span> of <span class='font-medium text-slate-600'>_TOTAL_</span> results",
        lengthMenu: 'Display _MENU_ records',
    },
});
